import React, { useState } from "react";
import { useForm } from "react-hook-form"; // Import useForm from react-hook-form
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { PostRequest } from "../../../Api/ApiFunctions";
import { ApiUrl } from "../../../Api/EndPoints";
import { access_control } from "../../../core/redux/action";
import { useDispatch } from "react-redux";

const SigninThree = () => {
  const dispatch = useDispatch();
  const route = all_routes;
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  // const [loading, setLoading] = useState(false); // For loading state
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm(); // Set up useForm
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  const onSubmit = async (data) => {
    const resp = await PostRequest(ApiUrl.authlogin, data);
    if (resp) {
      dispatch(access_control(resp.user));
      navigate(route.jobapplication);
    }
    return;
  };

  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper login-new">
          <div className="container">
            <div className="login-content user-login">
              <div className="login-logo">
                <ImageWithBasePath src="assets/img/logo-name.png" alt="img" />
                <Link to={route.dashboard} className="login-logo logo-white">
                  <ImageWithBasePath src="assets/img/logo-white.png" alt />
                </Link>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="login-userset">
                  <div className="login-userheading">
                    <h3>Sign In</h3>
                    <h4>
                      Access the Admin panel using your email and password.
                    </h4>
                  </div>
                  <div className="form-login">
                    <label className="form-label">Email Address</label>
                    <div className="form-addons">
                      <input
                        type="email"
                        className="form-control"
                        {...register("email", {
                          required: "Email is required",
                        })}
                      />
                      <ImageWithBasePath
                        src="assets/img/icons/mail.svg"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="form-login">
                    <label>Password</label>
                    <div className="pass-group">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        className="pass-input form-control"
                        {...register("password", {
                          required: "Password is required",
                        })}
                      />
                      <span
                        className={`fas toggle-password ${
                          isPasswordVisible ? "fa-eye" : "fa-eye-slash"
                        }`}
                        onClick={togglePasswordVisibility}
                        aria-label="Toggle password visibility"
                      ></span>
                    </div>
                  </div>

                  <div className="form-login authentication-check">
                    <div className="row">
                      <div className="col-6">
                        <div className="custom-control custom-checkbox">
                          <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                            <input type="checkbox" />
                            <span className="checkmarks" />
                            Remember me
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-login">
                    <button
                      className="btn btn-login"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : (
                        "Sign In"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
              <p>Copyright © 2024 Kürvers | Website by Strategy Kings</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SigninThree;
