import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../Router/all_routes";
import { useForm } from "react-hook-form"; // Import useForm from react-hook-form

import Addunits from "../../core/modals/inventory/addunits";
import AddCategory from "../../core/modals/inventory/addcategory";
import AddBrand from "../../core/modals/addbrand";
import { ArrowLeft } from "feather-icons-react/build/IconComponents";
import { PostRequest } from "../../Api/ApiFunctions";
import { ApiUrl } from "../../Api/EndPoints";

const AddCareer = () => {
  const route = all_routes;
  const [Responsibilities, setResponsibilities] = useState([]);
  const [Requirements, setRequirements] = useState([]);
  const [Res, setRes] = useState("");
  const [Req, setReq] = useState("");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    // setValue,
    // reset,
    formState: { isSubmitting },
  } = useForm(); // Set up useForm

  const AddDataArray = (type) => {
    if (type === "req") {
      if (Req) {
        setRequirements([...Requirements, Req]);
      }
      setReq("");
    }
    if (type === "res") {
      if (Res) {
        setResponsibilities([...Responsibilities, Res]);
      }
      setRes("");
    }
  };
  const RemoveDataArray = (type, item) => {
    if (type === "req") {
      setRequirements(Requirements.filter((r) => r != item));
    }
    if (type === "res") {
      setResponsibilities(Responsibilities.filter((r) => r != item));
    }
  };

  const onSubmit = async (data) => {
    data.responsibilities = Responsibilities;
    data.requirements = Requirements;

    const resp = await PostRequest(ApiUrl.career, data);
    if (resp) {
      navigate(route.career);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>New Career</h4>
              <h6>Create new Career</h6>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <div className="page-btn">
                <Link to={route.career} className="btn btn-secondary">
                  <ArrowLeft className="me-2" />
                  Back to Careers
                </Link>
              </div>
            </li>
          </ul>
        </div>
        {/* /add */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card">
            <div className="card-body add-product pb-0">
              <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="mb-3 add-product">
                    <label className="form-label">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("title", {
                        required: "title is required",
                      })}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="mb-3 add-product">
                    <label className="form-label">Location</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("location", {
                        required: "location is required",
                      })}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="mb-3 add-product">
                    <label className="form-label">Type</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("type", {
                        required: "location is required",
                      })}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3 add-product">
                    <label className="form-label">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("description", {
                        required: "location is required",
                      })}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="input-blocks add-product list">
                    <label>Responsibilities</label>
                    <div className="d-flex">
                      <input
                        type="text"
                        className="form-control list"
                        placeholder="Enter SKU"
                        value={Res}
                        onChange={(e) => setRes(e.target.value)}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={(e) => {
                          e.preventDefault(); // Prevents the default form submission
                          AddDataArray("res");
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="input-blocks add-product list">
                    <label>Requirements</label>
                    <div className="d-flex">
                      <input
                        type="text"
                        className="form-control list"
                        placeholder="Enter SKU"
                        value={Req}
                        onChange={(e) => setReq(e.target.value)}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={(e) => {
                          e.preventDefault(); // Prevents the default form submission
                          AddDataArray("req");
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                {(Responsibilities?.length > 0 || Requirements?.length > 0) && (
                  <hr />
                )}
                {Responsibilities?.length > 0 && (
                  <div className="col-lg-6 col-12 mb-4">
                    <label className="fw-bold">Responsibilities</label>
                    <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                      {Responsibilities.map((item, index) => (
                        <li
                          key={index}
                          style={{
                            marginBottom: "5px",
                          }}
                        >
                          <div className="px-2 d-flex justify-content-between bg-light text-black">
                            {item}{" "}
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => RemoveDataArray("res", item)}
                            >
                              <i className="fa-regular fa-circle-xmark text-danger"></i>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {Requirements?.length > 0 && (
                  <div className="col-lg-6 col-12 mb-4">
                    <label className="fw-bold">Requirements</label>
                    <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                      {Requirements.map((item, index) => (
                        <li
                          key={index}
                          style={{
                            marginBottom: "5px",
                          }}
                        >
                          <div className="px-2 d-flex justify-content-between bg-light text-black">
                            {item}{" "}
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => RemoveDataArray("req", item)}
                            >
                              <i className="fa-regular fa-circle-xmark text-danger"></i>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="btn-addproduct mb-4">
              <button type="button" className="btn btn-cancel me-2">
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  "Save career"
                )}
              </button>
            </div>
          </div>
        </form>
        {/* /add */}
      </div>
      <Addunits />
      <AddCategory />
      <AddBrand />
    </div>
  );
};

export default AddCareer;
