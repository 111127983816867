import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { RotateCcw } from "feather-icons-react/build/IconComponents";

import { PlusCircle } from "react-feather";
import Table from "../../core/pagination/datatable";
import AddRole from "../../core/modals/usermanagement/addrole";
import EditRole from "../../core/modals/usermanagement/editrole";
import { DeleteRequest, GetRequest } from "../../Api/ApiFunctions";
import { ApiUrl } from "../../Api/EndPoints";
import {
  DeletConfirmation,
  showDeleteConfirmationAlert,
} from "../MyComponents/SwalAlerts";
import { DataSourceSearch } from "../MyComponents/HandleSearch";
// import { all_routes } from "../../Router/all_routes";

const RolesPermissions = () => {
  const [dataSource, setDataSource] = useState([]);
  const [Search, setSearch] = useState("");

  const renderRefreshTooltip = (props, Data) => (
    <Tooltip id="refresh-tooltip" {...props}>
      {Data}
    </Tooltip>
  );
  const fetchData = async () => {
    const res = await GetRequest(ApiUrl.role);
    if (res) {
      setDataSource(res);
    }
  };
  const RemoveData = async (id) => {
    const res = await DeleteRequest(`${ApiUrl.role}/${id}`);
    if (res) {
      DeletConfirmation();
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "Role Name",
      dataIndex: "RoleName",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Created On",
      dataIndex: "dateAdded",
      sorter: (a, b) => a.dateAdded.length - b.dateAdded.length,
      render: (dateAdded) => new Date(dateAdded).toLocaleString(),
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {/* <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-units"
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link> */}
            <OverlayTrigger
              placement="top"
              overlay={(d) => renderRefreshTooltip(d, "Delete")}
            >
              <Link
                className="confirm-text p-2"
                onClick={() =>
                  showDeleteConfirmationAlert(record.id, RemoveData)
                }
              >
                <i data-feather="trash-2" className="feather-trash-2"></i>
              </Link>
            </OverlayTrigger>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Roles &amp; Permission</h4>
                <h6>Manage your roles</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={(d) => renderRefreshTooltip(d, "Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={fetchData}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <a
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#add-units"
              >
                <PlusCircle className="me-2" />
                Add New Role
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      value={Search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={DataSourceSearch(
                    Search,
                    ["RoleName"],
                    dataSource
                  )}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <AddRole onFinish={fetchData} />
      <EditRole />
    </div>
  );
};

export default RolesPermissions;
