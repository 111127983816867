import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/dist";
import AddBrand from "../../core/modals/inventory/addbrand";
import EditBrand from "../../core/modals/inventory/editbrand";
import Table from "../../core/pagination/datatable";
import {
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DeleteRequest, GetRequest, PostRequest } from "../../Api/ApiFunctions";
import { ApiUrl } from "../../Api/EndPoints";
import {
  DeletConfirmation,
  showDeleteConfirmationAlert,
} from "../MyComponents/SwalAlerts";
import { all_routes } from "../../Router/all_routes";
import { DataSourceSearch } from "../MyComponents/HandleSearch";
import { PermissionHandler } from "../MyComponents/PermissionHandler";
import { useSelector } from "react-redux";

const Career = () => {
  const GlobalState = useSelector((state) => state.access_control);
  const [dataSource, setDataSource] = useState([]);
  const [Selecteddata, setSelecteddata] = useState({});
  const [Search, setSearch] = useState("");
  const fetchData = async () => {
    const res = await GetRequest(ApiUrl.career);
    if (res) {
      setDataSource(res);
    }
  };

  const RemoveData = async (id) => {
    const res = await DeleteRequest(`${ApiUrl.career}/${id}`);
    if (res) {
      DeletConfirmation();
      fetchData();
    }
  };
  const setStatusChange = async (id) => {
    const res = await PostRequest(`${ApiUrl.career}/status/${id}`);
    if (res) {
      fetchData();
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const renderRefreshTooltip = (props, Data) => (
    <Tooltip id="refresh-tooltip" {...props}>
      {Data}
    </Tooltip>
  );

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      sorter: (a, b) => a.title.length - b.title.length,
    },

    {
      title: "Location",
      dataIndex: "location",
      sorter: (a, b) => a.location.length - b.location.length,
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: (a, b) => a.type.length - b.type.length,
    },
    {
      title: "Date Added",
      dataIndex: "dateAdded",
      sorter: (a, b) => a.dateAdded.length - b.dateAdded.length,
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (isActive) =>
        isActive ? (
          <span className="badge badge-linesuccess">Active</span>
        ) : (
          <span className="badge badge-linedanger">InActive</span>
        ),
      sorter: (a, b) => a.isActive.length - b.isActive.length,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {PermissionHandler(GlobalState, "career", "UPDATE") && (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={(d) =>
                    renderRefreshTooltip(
                      d,
                      `${record.isActive ? "DeActivate" : "Activate"}`
                    )
                  }
                >
                  <a
                    className="me-2 p-2"
                    onClick={() => setStatusChange(record.id)}
                  >
                    <i
                      data-feather="edit"
                      className={`${
                        record.isActive ? "feather-pause" : "feather-play"
                      }`}
                    ></i>
                  </a>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={(d) => renderRefreshTooltip(d, "Edit")}
                >
                  <Link
                    className="me-2 p-2"
                    to={`${all_routes.editcareer}?id=${record.id}`}
                    onClick={() => setSelecteddata(record)}
                  >
                    <i data-feather="edit" className="feather-edit"></i>
                  </Link>
                </OverlayTrigger>
              </>
            )}
            <OverlayTrigger
              placement="top"
              overlay={(d) => renderRefreshTooltip(d, "Delete")}
            >
              {PermissionHandler(GlobalState, "career", "DELETE") && (
                <Link
                  className="confirm-text p-2"
                  to="#"
                  onClick={() =>
                    showDeleteConfirmationAlert(record.id, RemoveData)
                  }
                >
                  <i data-feather="trash-2" className="feather-trash-2"></i>
                </Link>
              )}
            </OverlayTrigger>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Career</h4>
                <h6>Manage your Careers</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={(d) => renderRefreshTooltip(d, "Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={fetchData}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              {PermissionHandler(GlobalState, "career", "WRITE") && (
                <Link to={all_routes.addcareer} className="btn btn-added">
                  <PlusCircle className="me-2" />
                  Add New Career
                </Link>
              )}
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      value={Search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={DataSourceSearch(
                    Search,
                    ["title", "location", "type"],
                    dataSource
                  )}
                />
              </div>
            </div>
            {/* /product list */}
          </div>
        </div>
      </div>
      <AddBrand />
      <EditBrand editData={Selecteddata} />
    </div>
  );
};

export default Career;
