import React, { useEffect, useState } from "react";
import ImageDisplay from "../../core/img/imageDisplay";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import AddBanner from "../../core/modals/inventory/addbanner";
import EditBanner from "./editbanner";
import Table from "../../core/pagination/datatable";
import { DeleteRequest, GetRequest } from "../../Api/ApiFunctions";
import { ApiUrl } from "../../Api/EndPoints";
import {
  DeletConfirmation,
  showDeleteConfirmationAlert,
} from "../MyComponents/SwalAlerts";
import { DataSourceSearch } from "../MyComponents/HandleSearch";
import { PermissionHandler } from "../MyComponents/PermissionHandler";
import { useSelector } from "react-redux";

const Banner = () => {
  const GlobalState = useSelector((state) => state.access_control);
  const [Selecteddata, setSelecteddata] = useState({});

  const [dataSource, setDataSource] = useState([]);
  const [Search, setSearch] = useState("");
  const fetchData = async () => {
    const res = await GetRequest(ApiUrl.banner);
    if (res) {
      setDataSource(res);
    }
  };

  const RemoveData = async (id) => {
    const res = await DeleteRequest(`${ApiUrl.banner}/${id}`);
    if (res) {
      DeletConfirmation();
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderRefreshTooltip = (props, Data) => (
    <Tooltip id="refresh-tooltip" {...props}>
      {Data}
    </Tooltip>
  );

  const BannerKeys = { home: "Home Banner", gogreen: "Home Going Green" };

  const columns = [
    {
      title: "Banner",
      dataIndex: "banner",
      render: (text, record) => (
        <span className="productimgname">
          <Link to="#" className="product-img stock-img">
            <ImageDisplay alt="" src={record.banner} />
          </Link>
        </span>
      ),
      sorter: (a, b) => a.banner.length - b.banner.length,
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (data) => <p>{BannerKeys[data] ?? ""}</p>,
      sorter: (a, b) => a.type.length - b.type.length,
    },
    {
      title: "Title",
      dataIndex: "title",
      render: (data) => <div dangerouslySetInnerHTML={{ __html: data }} />,

      sorter: (a, b) => a.title.length - b.title.length,
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (data) => <div dangerouslySetInnerHTML={{ __html: data }} />,
      sorter: (a, b) => a.description.length - b.description.length,
    },
    {
      title: "Button text",
      dataIndex: "buttonText",
      sorter: (a, b) => a.buttonText.length - b.buttonText.length,
    },
    {
      title: "Button Link",
      dataIndex: "buttonLink",
      sorter: (a, b) => a.buttonLink.length - b.buttonLink.length,
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <OverlayTrigger
              placement="top"
              overlay={(d) => renderRefreshTooltip(d, "Edit")}
            >
              {PermissionHandler(GlobalState, "banner", "UPDATE") && (
                <Link
                  className="me-2 p-2"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#edit-category"
                  onClick={() => setSelecteddata(record)}
                >
                  <i data-feather="edit" className="feather-edit"></i>
                </Link>
              )}
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={(d) => renderRefreshTooltip(d, "Delete")}
            >
              {PermissionHandler(GlobalState, "banner", "DELETE") && (
                <Link
                  className="confirm-text p-2"
                  onClick={() =>
                    showDeleteConfirmationAlert(record.id, RemoveData)
                  }
                >
                  <i data-feather="trash-2" className="feather-trash-2"></i>
                </Link>
              )}
            </OverlayTrigger>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Banner list</h4>
                <h6>Manage your Banners</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={(d) => renderRefreshTooltip(d, "Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={fetchData}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            {PermissionHandler(GlobalState, "banner", "WRITE") && (
              <div className="page-btn">
                <Link
                  to="#"
                  className="btn btn-added"
                  data-bs-toggle="modal"
                  data-bs-target="#add-category"
                >
                  <PlusCircle className="me-2" />
                  Add banner Item
                </Link>
              </div>
            )}
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      value={Search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={DataSourceSearch(
                    Search,
                    ["type", "title", "description", "buttonText"],
                    dataSource
                  )}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>

      <AddBanner onFinish={fetchData} />
      <EditBanner onFinish={fetchData} editData={Selecteddata} />
    </div>
  );
};

export default Banner;
