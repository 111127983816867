import React, { useEffect } from "react";
import { useForm } from "react-hook-form"; // Import useForm from react-hook-form
import { PatchRequest } from "../../../Api/ApiFunctions";
import { ApiUrl } from "../../../Api/EndPoints";
// import { Modal } from "react-bootstrap";

const EditConfiguration = ({ OnFinish, editData }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    if (editData) {
      setValue("label", editData.label);
      setValue("value", editData.value);
    }
  }, [editData, setValue]);

  const onSubmit = async (data) => {
    const resp = await PatchRequest(
      `${ApiUrl.configuration}/${editData.id}`,
      data
    );
    const closeButton = document.querySelector(
      '#edit-units [data-bs-dismiss="modal"]'
    );

    if (resp && closeButton) {
      closeButton.click();
      OnFinish();
      reset();
    }
  };

  return (
    <div>
      {/* Edit Unit */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Configuration</h4>
                  </div>
                  <button
                    id="closebutton"
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                      <label className="form-label">Label</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={editData.label}
                        disabled
                        {...register("label", {
                          required: "name is required",
                        })}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">value</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={editData.value}
                        {...register("value", {
                          required: "Value is required",
                        })}
                      />
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <span className="spinner-border spinner-border-sm"></span>
                        ) : (
                          "Save Changes"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditConfiguration;
