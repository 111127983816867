import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { RotateCcw } from "react-feather";

import Table from "../../core/pagination/datatable";
import { GetRequest } from "../../Api/ApiFunctions";
import { ApiUrl } from "../../Api/EndPoints";

const ActivityLogs = () => {
  const [dataSource, setDataSource] = useState([]);

  const fetchData = async () => {
    const res = await GetRequest(ApiUrl.ActivityLog);

    if (res) {
      setDataSource(res);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const columns = [
    {
      title: "User",
      dataIndex: "user",
      render: (data) => <p>{data?.name}</p>,
      sorter: (a, b) => a.user.length - b.user.length,
    },
    {
      title: "Activity",
      dataIndex: "activity",
      sorter: (a, b) => a.activity.length - b.activity.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      sorter: (a, b) => a.action.length - b.action.length,
    },
    {
      title: "Date",
      dataIndex: "dateAdded",
      sorter: (a, b) => a.dateAdded.length - b.dateAdded.length,
      render: (dateAdded) => new Date(dateAdded).toLocaleString(),
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Activity Logs</h4>
                {/* <h6>Manage your subcategories</h6> */}
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={fetchData}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-responsive">
                <Table columns={columns} dataSource={dataSource} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default ActivityLogs;
