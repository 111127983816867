import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
// import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { RotateCcw } from "feather-icons-react/build/IconComponents";
import { PlusCircle } from "react-feather";
import Table from "../../core/pagination/datatable";
import AddUsers from "../../core/modals/usermanagement/addusers";
import EditUser from "../../core/modals/usermanagement/edituser";
import { DeleteRequest, GetRequest } from "../../Api/ApiFunctions";
import { ApiUrl } from "../../Api/EndPoints";
import {
  DeletConfirmation,
  showDeleteConfirmationAlert,
} from "../MyComponents/SwalAlerts";
import { DataSourceSearch } from "../MyComponents/HandleSearch";
import { useForm } from "react-hook-form";

const Users = () => {
  const [DataSource, setDataSource] = useState([]);
  const [Role, setRole] = useState([]);
  const [Search, setSearch] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { isSubmitting },
  } = useForm();

  const setSelectedData = (data) => {
    setValue("id", data.id);
    setValue("name", data.name);
    setValue("email", data.email);
    setValue("roleId", data.roleId);
    setValue("password", data.password);
  };

  const fetchRole = async () => {
    const res = await GetRequest(ApiUrl.role);
    if (res) {
      setRole(
        res.map((role) => ({
          value: role.id,
          label: role.RoleName,
        }))
      );
    }
    console.log(res);
  };

  const fetchData = async () => {
    const res = await GetRequest(ApiUrl.user);
    if (res) {
      setDataSource(res);
    }
  };

  const RemoveData = async (id) => {
    const res = await DeleteRequest(`${ApiUrl.user}/${id}`);
    if (res) {
      DeletConfirmation();
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
    fetchRole();
  }, []);

  const renderRefreshTooltip = (props, Data) => (
    <Tooltip id="refresh-tooltip" {...props}>
      {Data}
    </Tooltip>
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Email",
      dataIndex: "role",
      sorter: (a, b) => a.role?.RoleName?.length - b.role?.RoleName?.length,
      render: (role) => role?.RoleName ?? "No Role",
    },
    {
      title: "Created On",
      dataIndex: "dateAdded",
      sorter: (a, b) => new Date(a.dateAdded) - new Date(b.dateAdded), // Compare actual dates
      render: (dateAdded) => new Date(dateAdded).toLocaleString(),
    },
    {
      title: "Last Login",
      dataIndex: "lastLogin",
      sorter: (a, b) => new Date(a.lastLogin) - new Date(b.lastLogin), // Compare actual dates
      render: (lastLogin) =>
        lastLogin ? new Date(lastLogin).toLocaleString() : "-",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (text) => (
        <div>
          {text ? (
            <span className="badge badge-linesuccess">Active</span>
          ) : (
            <span className="badge badge-linedanger">InActive</span>
          )}
        </div>
      ),
      sorter: (a, b) => Number(b.status) - Number(a.status),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <OverlayTrigger
              placement="top"
              overlay={(e) => renderRefreshTooltip(e, "Edit")}
            >
              <Link
                className="me-2 p-2"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#edit-units"
                onClick={() => setSelectedData(record)}
              >
                <i data-feather="edit" className="feather-edit"></i>
              </Link>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={(e) => renderRefreshTooltip(e, "Delete")}
            >
              <Link
                className="confirm-text p-2"
                to="#"
                onClick={() =>
                  showDeleteConfirmationAlert(record.id, RemoveData)
                }
              >
                <i data-feather="trash-2" className="feather-trash-2"></i>
              </Link>
            </OverlayTrigger>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>User List</h4>
                <h6>Manage Your Users</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={(e) => renderRefreshTooltip(e, "Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={fetchData}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <a
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#add-units"
              >
                <PlusCircle className="me-2" />
                Add New User
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      value={Search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={DataSourceSearch(
                    Search,
                    ["name", "email", "role"],
                    DataSource
                  )}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      {Role && <AddUsers Role={Role} onFinish={fetchData} />}
      {Role && (
        <EditUser
          formMethods={{
            register,
            setValue,
            reset,
            getValues,
            handleSubmit,
            isSubmitting,
          }}
          Role={Role}
          onFinish={fetchData}
        />
      )}
    </div>
  );
};

export default Users;
