import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { PatchRequest } from "../../Api/ApiFunctions";
import { ApiUrl } from "../../Api/EndPoints";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EditBanner = ({ onFinish, editData }) => {
  // const [ButtonNeeded, setButtonNeeded] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    // getValues,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    if (editData) {
      setValue("id", editData.id);
      setValue("title", editData.title);
      setValue("description", editData.description);
      setValue("buttonText", editData.buttonText);
      setValue("buttonLink", editData.buttonLink);
    }
  }, [editData, setValue]);

  const onSubmit = async (data) => {
    const res = await PatchRequest(`${ApiUrl.banner}/${data.id}`, data);
    const closeButton = document.querySelector(
      '#edit-category [data-bs-dismiss="modal"]'
    );
    console.log(res);

    if (res) {
      closeButton.click();
      console.log("dome");
      onFinish();
      reset();
    } else {
      console.log(res);
    }
  };

  const customColors = [
    "#000000",
    "#e60000",
    "#ff9900",
    "#008dce",
    "#00a2e8",
    "#008000",
    "#0000ff",
    "#9900ff",
    "#ff00ff",
    "#ffffff",
    "#facccc",
    "#ffebcc",
    "#ffffcc",
    "#cce8cc",
    "#cce0f5",
    "#ebd6ff",
    // "#bbd6ff",
  ];
  return (
    <div>
      {/* Edit Category */}
      <div className="modal fade" id="edit-category">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Slider</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <div className="mb-3">
                      <label className="form-label">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("title", {
                          required: "title is required",
                        })}
                      />
                    </div> */}
                    <div className="mb-3">
                      <label className="form-label mb-3">Title</label>
                      <Controller
                        name="title"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Description is required" }}
                        render={({ field }) => (
                          <ReactQuill
                            {...field}
                            theme="snow"
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, 3, false] }],
                                ["bold", "italic", "underline", "strike"],
                                [
                                  { color: customColors },
                                  { background: customColors },
                                ],
                                [{ list: "ordered" }, { list: "bullet" }],
                                ["link", "image"],
                                ["clean"], // Remove formatting
                              ],
                            }}
                            placeholder="Type your description here..."
                          />
                        )}
                      />
                    </div>
                    {/* <div className="mb-3">
                      
                      <input
                        type="text"
                        className="form-control"
                        {...register("description", {
                          required: "description is required",
                        })}
                      />
                    </div> */}
                    <div className="mb-3">
                      <label className="form-label ">Description</label>
                      <Controller
                        name="description"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Description is required" }}
                        render={({ field }) => (
                          <ReactQuill
                            {...field}
                            theme="snow"
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, 3, false] }],
                                ["bold", "italic", "underline", "strike"],
                                [
                                  { color: customColors },
                                  { background: customColors },
                                ],
                                [{ list: "ordered" }, { list: "bullet" }],
                                ["link", "image"],
                                ["clean"], // Remove formatting
                              ],
                            }}
                            placeholder="Type your description here..."
                          />
                        )}
                      />
                    </div>
                    {/* <div className="mb-3">
                      <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                        <span className="status-label">Banner Button</span>
                        <input
                          type="checkbox"
                          className="check"
                          checked={ButtonNeeded}
                          onChange={(e) => setButtonNeeded(e.target.checked)}
                        />
                        <label htmlFor="user2" className="checktoggle" />
                      </div>
                    </div> */}

                    <div className="mb-3 input-blocks">
                      <label className="form-label">Button text</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("buttonText")}
                      />
                    </div>
                    <div className="mb-3 input-blocks">
                      <label className="form-label">Button Link</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("buttonLink")}
                      />
                    </div>

                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        // onClick={() => setButtonNeeded(false)}
                      >
                        Cancel
                      </button>
                      <button className="btn btn-submit" type="submit">
                        {isSubmitting ? (
                          <span className="spinner-border spinner-border-sm"></span>
                        ) : (
                          "Update Banner"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Category */}
    </div>
  );
};

export default EditBanner;
