import React from "react";

import * as Icon from "react-feather";

export const SidebarData = [
  {
    label: "Clients",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "access",
    submenuItems: [
      {
        label: "Job Applications",
        link: "/job-application",
        access_id: "job_application",
        icon: <Icon.FileMinus />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
  {
    label: "CMS",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "cms",
    submenuItems: [
      {
        access_id: "banner",
        label: "Sliders",
        link: "/banner",
        icon: <Icon.BarChart />,
        showSubRoute: false,
        submenu: false,
      },
      {
        access_id: "career",
        label: "Careers",
        link: "/career",
        icon: <Icon.FilePlus />,
        showSubRoute: false,
        submenu: false,
      },
      {
        access_id: "configuration",
        label: "General Data",
        link: "/configuration",
        icon: <Icon.Settings />,
        showSubRoute: false,
        submenu: false,
      },
      {
        access_id: "product_range",
        label: "Product Range",
        link: "/product-range",
        icon: <Icon.ShoppingBag />,
        showSubRoute: false,
        submenu: false,
      },
      {
        access_id: "kurvers_valves",
        label: "Kurvers Valves",
        link: "/kurvers-valves",
        icon: <Icon.PieChart />,
        showSubRoute: false,
        submenu: false,
      },
      {
        access_id: "contact_us",
        label: "Contact us",
        link: "/contactus",
        icon: <Icon.PhoneCall />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
  {
    label: "Access Management",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "access",
    submenuItems: [
      {
        access_id: "admin",
        label: "Users",
        link: "/users",
        icon: <Icon.User />,
        showSubRoute: false,
        submenu: false,
      },
      {
        access_id: "admin",
        label: "Roles",
        link: "/roles-permissions",
        icon: <Icon.Users />,
        showSubRoute: false,
        submenu: false,
      },
      {
        access_id: "admin",
        label: "Permissions",
        link: "/permissions",
        icon: <Icon.Tag />,
        showSubRoute: false,
        submenu: false,
      },
      {
        access_id: "admin",
        label: "Actitivity Logs",
        link: "/activity-logs",
        icon: <Icon.Speaker />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
];
