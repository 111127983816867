const Permissions = ["READ", "WRITE", "UPDATE", "DELETE"];

export const access_control = {
  UserName: "Fadhil Abdulla",
  UserId: 12,
  UserRole: "Super Admin",
  Permission: {
    job_application: Permissions,
    banner: Permissions,
    career: Permissions,
    configuration: Permissions,
    admin: Permissions,
  },
};
