import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

export const DeletConfirmation = () => {
  MySwal.fire({
    title: "Deleted!",
    text: "Your file has been deleted.",
    className: "btn btn-success",
    confirmButtonText: "OK",
    customClass: {
      confirmButton: "btn btn-success",
    },
  });
};

export const showDeleteConfirmationAlert = (id, onConfirm) => {
  MySwal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    showCancelButton: true,
    confirmButtonColor: "#00ff00",
    confirmButtonText: "Yes, delete it!",
    cancelButtonColor: "#ff0000",
    cancelButtonText: "Cancel",
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm(id);
    } else {
      MySwal.close();
    }
  });
};
