import axios from "axios";

// Create an instance of axios with default settings
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Ensure that API base URL is correctly set in your .env file
  headers: {
    "Content-Type": "application/json",
  },
});

export const HeaderGroup = {
  none: {},
  multipart: { "Content-Type": "multipart/form-data" },
};

export const handleError = (error) => {
  console.log(error?.response?.data?.message[0] ?? "Something Went Wrong");
};

// GET Request
export const GetRequest = async (api, params = {}) => {
  return apiClient
    .get(api, { params })
    .then((response) =>
      response && response?.data ? response.data : handleError()
    )
    .catch((error) => {
      handleError(error); // Only throw error if you need to log or handle it differently
    });
};

// POST Request
export const PostRequest = async (api, body, header = HeaderGroup.none) => {
  return apiClient
    .post(api, body, { headers: header })
    .then((response) =>
      response && response?.data ? response.data : handleError()
    )
    .catch((error) => {
      handleError(error); // Only throw error if you need to log or handle it differently
    });
};

// PUT Request (usually used to update resources, replace the whole resource)
export const PutRequest = async (api, body) => {
  return apiClient
    .put(api, body)
    .then((response) =>
      response && response?.data ? response.data : handleError()
    )
    .catch((error) => {
      handleError(error); // Only throw error if you need to log or handle it differently
    });
};

// PATCH Request (usually used to partially update a resource)
export const PatchRequest = async (api, body) => {
  return apiClient
    .patch(api, body)
    .then((response) =>
      response && response?.data ? response.data : handleError()
    )
    .catch((error) => {
      handleError(error); // Only throw error if you need to log or handle it differently
    });
};

// DELETE Request
export const DeleteRequest = async (api) => {
  return apiClient
    .delete(api)
    .then((response) =>
      response && response?.data ? response.data : handleError()
    )
    .catch((error) => {
      handleError(error); // Only throw error if you need to log or handle it differently
    });
};
