import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../core/pagination/datatable";
// import AddConfiguration from "../../core/modals/inventory/addConfiguration";
import AddContactUs from "../../core/modals/inventory/addContactUs";
import {
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DeleteRequest, GetRequest } from "../../Api/ApiFunctions";
import { ApiUrl } from "../../Api/EndPoints";
import {
  DeletConfirmation,
  showDeleteConfirmationAlert,
} from "../MyComponents/SwalAlerts";
import { DataSourceSearch } from "../MyComponents/HandleSearch";
import { PermissionHandler } from "../MyComponents/PermissionHandler";
import { useSelector } from "react-redux";
import EditContactUs from "../../core/modals/inventory/editcontactus";

const ContactUS = () => {
  const GlobalState = useSelector((state) => state.access_control);
  const [dataSource, setDataSource] = useState([]);
  const [Selecteddata, setSelecteddata] = useState({});
  const [Search, setSearch] = useState("");

  const fetchData = async () => {
    const res = await GetRequest(`${ApiUrl.configuration}/contact-us`);
    if (res) {
      setDataSource(
        res.map((item) => ({
          ...JSON.parse(item.value),
          CountryName: item.label,
          id: item.id,
        }))
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const RemoveData = async (id) => {
    const res = await DeleteRequest(`${ApiUrl.configuration}/${id}`);
    if (res) {
      DeletConfirmation();
      fetchData();
    }
  };

  const renderRefreshTooltip = (props, Data) => (
    <Tooltip id="refresh-tooltip" {...props}>
      {Data}
    </Tooltip>
  );

  const CountryKeys = {
    contact_usa: "USA",
    contact_ger1: "Germany",
    contact_ger2: "Germany",
    contact_ner: "Netherlands",
    contact_italy: "Italy",
    contact_uk: "UK",
    contact_france: "France",
    contact_china: "China",
    contact_sing: "Singapore",
    contact_uae: "Uae",
  };

  const columns = [
    {
      title: "Country",
      dataIndex: "CountryName",
      render: (data) => CountryKeys[data],
      sorter: (a, b) => a.unit.length - b.unit.length,
    },
    {
      title: "Heading",
      dataIndex: "heading",
      sorter: (a, b) => a.unit.length - b.unit.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.unit.length - b.unit.length,
    },
    {
      title: "Address 1",
      dataIndex: "address1",
      sorter: (a, b) => a.shortname.length - b.shortname.length,
    },
    {
      title: "Address 2",
      dataIndex: "address2",
      sorter: (a, b) => a.shortname.length - b.shortname.length,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      sorter: (a, b) => a.unit.length - b.unit.length,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <OverlayTrigger
              placement="top"
              overlay={(d) => renderRefreshTooltip(d, "Edit")}
            >
              {PermissionHandler(GlobalState, "career", "UPDATE") && (
                <Link
                  className="me-2 p-2"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#edit-units"
                  onClick={() => setSelecteddata(record)}
                >
                  <i data-feather="edit" className="feather-edit"></i>
                </Link>
              )}
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={(d) => renderRefreshTooltip(d, "Delete")}
            >
              {PermissionHandler(GlobalState, "career", "DELETE") && (
                <Link
                  className="confirm-text p-2"
                  to="#"
                  onClick={() =>
                    showDeleteConfirmationAlert(record.id, RemoveData)
                  }
                >
                  <i data-feather="trash-2" className="feather-trash-2"></i>
                </Link>
              )}
            </OverlayTrigger>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Contact Us</h4>
                <h6>Manage your Contact us details</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={(d) => renderRefreshTooltip(d, "Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={fetchData}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              {PermissionHandler(GlobalState, "career", "WRITE") && (
                <a
                  to="#"
                  className="btn btn-added"
                  data-bs-toggle="modal"
                  data-bs-target="#add-units"
                >
                  <PlusCircle className="me-2" />
                  Add New Address
                </a>
              )}
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      value={Search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={DataSourceSearch(
                    Search,
                    ["label", "value"],
                    dataSource
                  )}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
        <AddContactUs OnFinish={fetchData} />

        <EditContactUs OnFinish={fetchData} editData={Selecteddata} />
      </div>
    </>
  );
};

export default ContactUS;
