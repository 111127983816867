import React from "react";
import { useForm } from "react-hook-form";
import { PostRequest } from "../../../Api/ApiFunctions";
import { ApiUrl } from "../../../Api/EndPoints";

const AddRole = ({ onFinish }) => {
  const {
    register,
    handleSubmit,
    reset,
    // getValues,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    const resp = await PostRequest(ApiUrl.role, data);
    const closeButton = document.querySelector('[data-bs-dismiss="modal"]');
    if (resp && closeButton) {
      closeButton.click();
      onFinish();
      reset();
    }
  };

  return (
    <div>
      {/* Add Role */}
      <div className="modal fade" id="add-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Create Role</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-0">
                      <label className="form-label">Role Name</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("RoleName", {
                          required: "title is required",
                        })}
                      />
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-submit">
                        {isSubmitting ? (
                          <span className="spinner-border spinner-border-sm"></span>
                        ) : (
                          "Create Role"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Role */}
    </div>
  );
};

export default AddRole;
