import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../../Router/all_routes";
import { useForm } from "react-hook-form"; // Import useForm from react-hook-form

import Addunits from "../../core/modals/inventory/addunits";
import AddCategory from "../../core/modals/inventory/addcategory";
import AddBrand from "../../core/modals/addbrand";
import { GetRequest, PatchRequest } from "../../Api/ApiFunctions";
import { ApiUrl } from "../../Api/EndPoints";

const KurversValves = () => {
  const route = all_routes;
  const [Responsibilities, setResponsibilities] = useState([]);
  const [Res, setRes] = useState("");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    // reset,
    formState: { isSubmitting },
  } = useForm(); // Set up useForm

  const fetchData = async () => {
    const res = await GetRequest(`${ApiUrl.configuration}/kurvers-valves`);
    if (res) {
      let data = JSON.parse(res?.value);
      setValue("confId", res.id);
      setResponsibilities(data?.products ?? {});
      // setDataSource(res);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const AddDataArray = () => {
    if (Res) {
      setResponsibilities({ ...Responsibilities, [Res]: [] });
    }
    setRes("");
  };
  const RemoveDataArray = (item) => {
    setResponsibilities((prevData) => {
      const newData = { ...prevData }; // Create a shallow copy of the object
      delete newData[item]; // Remove the key
      return newData; // Update state with the new object
    });
  };
  const RemoveArraySubItem = (key, item) => {
    setResponsibilities((prevData) => {
      const newData = { ...prevData }; // Create a shallow copy of the object
      newData[key] = newData[key].filter((subItem) => subItem !== item); // Remove the key
      return newData; // Update state with the new object
    });
  };

  const onSubmit = async () => {
    const resp = await PatchRequest(
      `${ApiUrl.configuration}/${getValues("confId")}`,
      {
        label: "kurvers-valves",
        value: JSON.stringify({
          // heading: data.heading,
          // description: data.description,
          products: Responsibilities,
        }),
      }
    );
    if (resp) {
      navigate(route.jobapplication);
    }
  };

  const AddSubItem = () => {
    if (
      !getValues("ProductSubItem") ||
      !getValues("ProductSelected") ||
      getValues("ProductSubItem") == "" ||
      getValues("ProductSelected") == ""
    ) {
      console.log("no item");
      return;
    }

    let item = { ...Responsibilities };
    if (getValues("ProductSubItemType") === "link") {
      item[getValues("ProductSelected")] = getValues("ProductSubItem");
    } else {
      Array.isArray(item[getValues("ProductSelected")])
        ? item[getValues("ProductSelected")].push(getValues("ProductSubItem"))
        : (item[getValues("ProductSelected")] = [getValues("ProductSubItem")]);
    }
    setValue("ProductSubItem", "");
    setResponsibilities(item);
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Kurvers Valves</h4>
              {/* <h6>Create new Career</h6> */}
            </div>
          </div>
        </div>
        {/* /add */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card">
            <div className="card-body add-product pb-0">
              <div className="row">
                {/* <div className="col-lg-6 col-sm-6 col-12">
                  <div className="mb-3 add-product">
                    <label className="form-label">Heading</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("heading", {
                        required: "title is required",
                      })}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="mb-3 add-product">
                    <label className="form-label">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("description", {
                        required: "location is required",
                      })}
                    />
                  </div>
                </div> */}
                <div className="col-lg-6 col-12">
                  <div className="input-blocks add-product list">
                    <label>Products</label>
                    <div className="d-flex">
                      <input
                        type="text"
                        className="form-control list"
                        placeholder="Enter Product name"
                        value={Res}
                        onChange={(e) => setRes(e.target.value)}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={(e) => {
                          e.preventDefault(); // Prevents the default form submission
                          AddDataArray();
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                {Object.keys(Responsibilities)?.length > 0 && (
                  <div className="col-12 mb-4 d-flex">
                    <ul className="d-flex">
                      {Object.keys(Responsibilities).map((item, index) => (
                        <li
                          key={index}
                          style={{
                            marginBottom: "5px",
                            marginRight: "10px",
                          }}
                        >
                          <div className="px-2 d-flex bg-light text-black">
                            {item}{" "}
                            <div
                              style={{ cursor: "pointer", paddingLeft: "10px" }}
                              onClick={() => RemoveDataArray(item)}
                            >
                              <i className="fa-regular fa-circle-xmark text-danger"></i>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {Object.keys(Responsibilities)?.length > 0 && <hr />}

                <div className="col-12">
                  <div className="input-blocks add-product list">
                    <label>Product Points</label>
                    <div className="d-flex align-items-center">
                      {/* Select dropdown on the left */}
                      <select
                        className="form-select me-2"
                        // onChange={(val)}
                        // value={selectedOption}
                        onChange={(e) =>
                          setValue("ProductSelected", e.target.value)
                        }
                      >
                        <option value="">Select a Product</option>
                        {Object.keys(Responsibilities).map((item) => {
                          return (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                      <select
                        className="form-select me-2"
                        onChange={(e) =>
                          setValue("ProductSubItemType", e.target.value)
                        }
                      >
                        <option value={"point"}>Points</option>
                        <option value={"link"}>Link</option>
                      </select>

                      {/* Input field in the middle */}
                      <input
                        type="text"
                        className="form-control me-2"
                        placeholder="Points"
                        {...register("ProductSubItem")}
                      />

                      {/* Button on the right */}
                      <button
                        className="btn btn-primary"
                        onClick={(e) => {
                          e.preventDefault(); // Prevents the default form submission
                          AddSubItem();
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                {Object.keys(Responsibilities)?.length > 0 &&
                  Object.keys(Responsibilities).map((dat, i) => (
                    <div key={i} className="col-lg-6 col-12 mb-4">
                      <label className="fw-bold">{dat}</label>
                      <ul
                        style={{ listStyleType: "disc", paddingLeft: "20px" }}
                      >
                        {Array.isArray(Responsibilities[dat]) ? (
                          Responsibilities[dat].map((item, index) => (
                            <li
                              key={index}
                              style={{
                                marginBottom: "5px",
                              }}
                            >
                              <div className="px-2 d-flex justify-content-between bg-light text-black">
                                {item}{" "}
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => RemoveArraySubItem(dat, item)}
                                >
                                  <i className="fa-regular fa-circle-xmark text-danger"></i>
                                </div>
                              </div>
                            </li>
                          ))
                        ) : (
                          <a href={Responsibilities[dat]} target="blank">
                            {Responsibilities[dat]}
                          </a>
                        )}
                      </ul>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="btn-addproduct mb-4">
              <button type="button" className="btn btn-cancel me-2">
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  "Save Kurvers Valves"
                )}
              </button>
            </div>
          </div>
        </form>
        {/* /add */}
      </div>
      <Addunits />
      <AddCategory />
      <AddBrand />
    </div>
  );
};

export default KurversValves;
