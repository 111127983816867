import React, { useState } from "react";
import Select from "react-select";
// import { useForm } from "react-hook-form";
import { PatchRequest } from "../../../Api/ApiFunctions";
import { ApiUrl } from "../../../Api/EndPoints";

const EditUser = ({ formMethods, Role, onFinish }) => {
  const { register, setValue, getValues, isSubmitting, handleSubmit, reset } =
    formMethods;

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const closeButton = document.querySelector(
    '#edit-units [data-bs-dismiss="modal"]'
  );
  const onSubmit = async (req) => {
    const res = await PatchRequest(`${ApiUrl.user}/${getValues("id")}`, req);
    if (res && closeButton) {
      closeButton.click();
      onFinish();
      reset();
    } else {
      console.log(res);
    }
  };

  return (
    <div>
      {/* Edit User */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit User</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Name</label>
                          <input
                            type="text"
                            placeholder="Thomas"
                            {...register("name", {
                              required: "name is required",
                            })}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Email</label>
                          <input
                            type="email"
                            placeholder="thomas@example.com"
                            {...register("email", {
                              required: "name is required",
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Role</label>
                          <Select
                            options={Role}
                            placeholder="Choose Role"
                            classNamePrefix="react-select"
                            value={Role.find(
                              (role) => role.value === getValues("roleId")
                            )}
                            // value={getValues("roleId")}
                            onChange={(val) => setValue("roleId", val?.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Password</label>
                          <div className="pass-group">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="pass-input"
                              placeholder="**********"
                              {...register("password", {
                                required: "name is required",
                              })}
                            />
                            <span
                              className={`fas toggle-password ${
                                showPassword ? "fa-eye" : "fa-eye-slash"
                              }`}
                              onClick={handleTogglePassword}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button to="#" className="btn btn-submit">
                        {isSubmitting ? (
                          <span className="spinner-border spinner-border-sm"></span>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit User */}
    </div>
  );
};

export default EditUser;
