import React, { useState } from "react";
// import Select from "react-select";
import { HeaderGroup, PostRequest } from "../../../Api/ApiFunctions";
import { ApiUrl } from "../../../Api/EndPoints";
import { useForm, Controller } from "react-hook-form";
import ImageWithBasePath from "../../img/imagewithbasebath";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddBanner = ({ onFinish }) => {
  // const [ButtonNeeded, setButtonNeeded] = useState(false);
  const [FileAdded, setFileAdded] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    // watch,
    // getValues,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    data.file = data.file[0];
    const resp = await PostRequest(ApiUrl.banner, data, HeaderGroup.multipart);
    const closeButton = document.querySelector('[data-bs-dismiss="modal"]');
    if (resp && closeButton) {
      closeButton.click();
      onFinish();
      // setButtonNeeded(false);
      reset();
    }
  };

  const BannerKeys = [
    { value: "home", label: "Home Banner" },
    { value: "gogreen", label: "Home Going Green" },
  ];
  const customColors = [
    "#000000",
    "#e60000",
    "#ff9900",
    "#008dce",
    "#00a2e8",
    "#008000",
    "#0000ff",
    "#9900ff",
    "#ff00ff",
    "#ffffff",
    "#facccc",
    "#ffebcc",
    "#ffffcc",
    "#cce8cc",
    "#cce0f5",
    "#ebd6ff",
    // "#bbd6ff",
  ];

  return (
    <div>
      {/* Add Category */}
      <div className="modal fade" id="add-category">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add New Banner</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    // onClick={() => setButtonNeeded(false)}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-blocks image-upload-down">
                      <label> Upload Banner Image</label>
                      <div className="image-upload download">
                        <input
                          type="file"
                          {...register("file", {
                            required: "title is required",
                            onChange: () => setFileAdded(true),
                          })}
                        />
                        <div className="image-uploads">
                          {
                            // <ImageWithBasePath
                            //   src="assets/img/download-img.png"
                            //   alt="Uploaded preview"
                            // />
                          }
                          {/* <h4>
                            {FileAdded?.length > 0 ? "Image Uploaded" : ""}
                          </h4> */}
                          {/* <h4>
                            Drag and drop a <span>Image to upload</span>
                          </h4> */}
                          <ImageWithBasePath
                            src={"assets/img/download-img.png"}
                            alt="Uploaded preview"
                          />
                          <h4>
                            {FileAdded
                              ? "Image Uploaded Successfully"
                              : "Drag and drop a Image to upload"}
                          </h4>
                        </div>
                      </div>
                    </div>
                    {/* <div className="mb-3">
                      <label className="form-label">Parent Category</label>
                      <Select
                        classNamePrefix="react-select"
                        options={categories}
                        placeholder="Newest"
                      />
                    </div> */}
                    {/* <div className="mb-3">
                      <label className="form-label">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("title", {
                          required: "title is required",
                        })}
                      />
                    </div> */}
                    <div className="mb-3">
                      <label className="form-label mb-3">Title</label>
                      <Controller
                        name="title"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Description is required" }}
                        render={({ field }) => (
                          <ReactQuill
                            {...field}
                            theme="snow"
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, 3, false] }],
                                ["bold", "italic", "underline", "strike"],
                                [
                                  { color: customColors },
                                  { background: customColors },
                                ],
                                [{ list: "ordered" }, { list: "bullet" }],
                                ["link", "image"],
                                ["clean"], // Remove formatting
                              ],
                            }}
                            placeholder="Type your description here..."
                          />
                        )}
                      />
                    </div>
                    {/* <div className="mb-3">
                      <label className="form-label">Description</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("description", {
                          required: "description is required",
                        })}
                      />
                    </div> */}
                    <div className="mb-3">
                      <label className="form-label ">Description</label>
                      <Controller
                        name="description"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Description is required" }}
                        render={({ field }) => (
                          <ReactQuill
                            {...field}
                            theme="snow"
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, 3, false] }],
                                ["bold", "italic", "underline", "strike"],
                                [
                                  { color: customColors },
                                  { background: customColors },
                                ],
                                [{ list: "ordered" }, { list: "bullet" }],
                                ["link", "image"],
                                ["clean"], // Remove formatting
                              ],
                            }}
                            placeholder="Type your description here..."
                          />
                        )}
                      />
                    </div>

                    {/* <div className="mb-3 input-blocks">
                      <label className="form-label">Type</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("type", {
                          required: "type is required",
                        })}
                      />
                    </div> */}
                    <div className="mb-3">
                      <div className="input-blocks">
                        <label>Type</label>
                        <Select
                          options={BannerKeys}
                          placeholder="Choose Country"
                          classNamePrefix="react-select"
                          onChange={(val) => setValue("type", val?.value)}
                        />
                      </div>
                    </div>
                    {/* <div className="mb-3">
                      <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                        <span className="status-label">Banner Button</span>
                        <input
                          type="checkbox"
                          id="user2"
                          className="check"
                          value={ButtonNeeded}
                          onChange={(e) => setButtonNeeded(e.target.checked)}
                        />
                        <label htmlFor="user2" className="checktoggle" />
                      </div>
                    </div> */}

                    <div className="mb-3 input-blocks">
                      <label className="form-label">Button text</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("buttonText")}
                      />
                    </div>
                    <div className="mb-3 input-blocks">
                      <label className="form-label">Button Link</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("buttonLink")}
                      />
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        // onClick={() => setButtonNeeded(false)}
                      >
                        Cancel
                      </button>
                      <button className="btn btn-submit" type="submit">
                        {isSubmitting ? (
                          <span className="spinner-border spinner-border-sm"></span>
                        ) : (
                          "Add Banner"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}
    </div>
  );
};

export default AddBanner;
