export const ApiUrl = {
  authlogin: "/auth/login",
  user: "/user",
  role: "/role",
  configuration: "/configuration",
  permission: "/permission",
  ActivityLog: "/configuration/activity-logs",
  career: "/careerapi",
  careerapply: "/careerapply",
  banner: "/banner",
  image: "/uploads",
};
