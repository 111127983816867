import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../core/pagination/datatable";
import AddConfiguration from "../../core/modals/inventory/addConfiguration";
import EditConfiguration from "../../core/modals/inventory/editconfiguration";
import {
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DeleteRequest, GetRequest } from "../../Api/ApiFunctions";
import { ApiUrl } from "../../Api/EndPoints";
import {
  DeletConfirmation,
  showDeleteConfirmationAlert,
} from "../MyComponents/SwalAlerts";
import { DataSourceSearch } from "../MyComponents/HandleSearch";
import { PermissionHandler } from "../MyComponents/PermissionHandler";
import { useSelector } from "react-redux";

const Configuration = () => {
  const GlobalState = useSelector((state) => state.access_control);
  const [dataSource, setDataSource] = useState([]);
  const [Selecteddata, setSelecteddata] = useState({});
  const [Search, setSearch] = useState("");

  const fetchData = async () => {
    const res = await GetRequest(ApiUrl.configuration);
    if (res) {
      setDataSource(res);
    }
  };

  const RemoveData = async (id) => {
    const res = await DeleteRequest(`${ApiUrl.configuration}/${id}`);
    if (res) {
      DeletConfirmation();
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderRefreshTooltip = (props, Data) => (
    <Tooltip id="refresh-tooltip" {...props}>
      {Data}
    </Tooltip>
  );

  const columns = [
    {
      title: "Label",
      dataIndex: "label",
      sorter: (a, b) => a.unit.length - b.unit.length,
    },
    {
      title: "Value",
      dataIndex: "value",
      sorter: (a, b) => a.shortname.length - b.shortname.length,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      sorter: (a, b) => a.updatedAt.length - b.updatedAt.length,
      render: (updatedAt) => new Date(updatedAt).toLocaleString(),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <OverlayTrigger
              placement="top"
              overlay={(d) => renderRefreshTooltip(d, "Edit")}
            >
              {PermissionHandler(GlobalState, "career", "UPDATE") && (
                <Link
                  className="me-2 p-2"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#edit-units"
                  onClick={() => setSelecteddata(record)}
                >
                  <i data-feather="edit" className="feather-edit"></i>
                </Link>
              )}
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={(d) => renderRefreshTooltip(d, "Delete")}
            >
              {PermissionHandler(GlobalState, "career", "DELETE") && (
                <Link
                  className="confirm-text p-2"
                  to="#"
                  onClick={() =>
                    showDeleteConfirmationAlert(record.id, RemoveData)
                  }
                >
                  <i data-feather="trash-2" className="feather-trash-2"></i>
                </Link>
              )}
            </OverlayTrigger>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Configuration</h4>
                <h6>Manage your Configurations</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={(d) => renderRefreshTooltip(d, "Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={fetchData}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              {PermissionHandler(GlobalState, "career", "WRITE") && (
                <a
                  to="#"
                  className="btn btn-added"
                  data-bs-toggle="modal"
                  data-bs-target="#add-units"
                >
                  <PlusCircle className="me-2" />
                  Add New Configuration
                </a>
              )}
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      value={Search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={DataSourceSearch(
                    Search,
                    ["label", "value"],
                    dataSource
                  )}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
        <AddConfiguration OnFinish={fetchData} />

        <EditConfiguration OnFinish={fetchData} editData={Selecteddata} />
      </div>
    </>
  );
};

export default Configuration;
