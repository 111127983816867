import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { RotateCcw } from "feather-icons-react/build/IconComponents";

import AddCategoryList from "../../core/modals/inventory/addcategorylist";
import EditCategoryList from "../../core/modals/inventory/editcategorylist";

import Table from "../../core/pagination/datatable";
import { GetRequest } from "../../Api/ApiFunctions";
import { ApiUrl } from "../../Api/EndPoints";
import { DataSourceSearch } from "../MyComponents/HandleSearch";

const JobApplication = () => {
  const [dataSource, setDataSource] = useState([]);
  const [Search, setSearch] = useState("");
  const fetchData = async () => {
    const res = await GetRequest(ApiUrl.careerapply);
    if (res) {
      setDataSource(res);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const DownloadTootTip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Download Resume
    </Tooltip>
  );
  const DownloadCoverLetter = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Download Cover Letter
    </Tooltip>
  );
  const Downloadresume = (resume) => {
    if (!resume.includes("uploads")) {
      alert("failed to load the file");
      return;
    }
    const fileUrl = `${process.env.REACT_APP_IMAGE_BASE_URL}/${resume}`;
    const newWindow = window.open(fileUrl, "_blank");
    if (!newWindow) {
      alert("Please allow popups for this site");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      title: "Applied at",
      dataIndex: "appliedAt",
      sorter: (a, b) => a.appliedAt.length - b.appliedAt.length,
      render: (appliedAt) => new Date(appliedAt).toLocaleString(),
    },
    {
      title: "Job Title",
      dataIndex: "job",
      sorter: (a, b) => a?.job?.length - b?.job?.length,
      render: (job) => job?.title,
    },

    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   render: (text) => (
    //     <span className="badge badge-linesuccess">
    //       <Link to="#"> {text}</Link>
    //     </span>
    //   ),
    //   sorter: (a, b) => a.status.length - b.status.length,
    // },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {/* <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-category"
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link> */}
            <OverlayTrigger placement="top" overlay={DownloadTootTip}>
              <a
                className="me-2 p-2"
                onClick={() => Downloadresume(record.resume)}
              >
                <i data-feather="edit" className="feather-download"></i>
              </a>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={DownloadCoverLetter}>
              <a
                className="me-2 p-2"
                onClick={() => Downloadresume(record.coverLetter)}
              >
                <i data-feather="edit" className="feather-download"></i>
              </a>
            </OverlayTrigger>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Job Applicaion</h4>
                <h6>Manage your Job Applications</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={fetchData}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      value={Search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={DataSourceSearch(
                    Search,
                    ["name", "email", "phone", "job"],
                    dataSource
                  )}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <AddCategoryList />
      <EditCategoryList />
    </div>
  );
};

export default JobApplication;
