import React from "react";
import { useForm } from "react-hook-form"; // Import useForm from react-hook-form
import { PostRequest } from "../../../Api/ApiFunctions";
import { ApiUrl } from "../../../Api/EndPoints";
import Select from "react-select";

const AddContactUs = ({ OnFinish }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    const resp = await PostRequest(ApiUrl.configuration, {
      label: data.key,
      value: JSON.stringify({
        heading: data.heading,
        email: data.email,
        address1: data.address1,
        address2: data.address2,
        phone: data.phone,
      }),
    });
    const closeButton = document.querySelector('[data-bs-dismiss="modal"]');
    if (resp && closeButton) {
      console.log(closeButton);

      closeButton.click();
      OnFinish();
      reset();
    }
  };
  const CountryKeys = [
    { value: "contact_usa", label: "USA" },
    { value: "contact_ger1", label: "Germany" },
    { value: "contact_ger2", label: "Germany" },
    { value: "contact_ner", label: "Netherlands" },
    { value: "contact_italy", label: "Italy" },
    { value: "contact_uk", label: "UK" },
    { value: "contact_france", label: "France" },
    { value: "contact_china", label: "China" },
    { value: "contact_sing", label: "Singapore" },
    { value: "contact_uae", label: "Uae" },
  ];
  return (
    <div>
      {/* Add Unit */}
      <div className="modal fade" id="add-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>New ContactUs Address</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <div className="mb-3">
                      <label className="form-label">Key</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="key for contact us Map"
                        {...register("key", {
                          required: "name is required",
                        })}
                      />
                    </div> */}
                    <div className="mb-3">
                      <div className="input-blocks">
                        <label>Country</label>
                        <Select
                          options={CountryKeys}
                          placeholder="Choose Country"
                          classNamePrefix="react-select"
                          onChange={(val) => setValue("key", val?.value)}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Heading</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("heading", {
                          required: "name is required",
                        })}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        {...register("email")}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Address 1</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("address1")}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Address 2</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("address2")}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("phone")}
                      />
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <span className="spinner-border spinner-border-sm"></span>
                        ) : (
                          "Create Configuration"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Unit */}
    </div>
  );
};

export default AddContactUs;
