import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PlusCircle } from "react-feather";

import { RotateCcw } from "feather-icons-react/build/IconComponents";
import {
  DeleteRequest,
  GetRequest,
  PatchRequest,
} from "../../Api/ApiFunctions";
import { ApiUrl } from "../../Api/EndPoints";

import AddPermission from "../../core/modals/usermanagement/addPermission";
import {
  DeletConfirmation,
  showDeleteConfirmationAlert,
} from "../MyComponents/SwalAlerts";

const Permissions = () => {
  const renderRefreshTooltip = (props, Data) => (
    <Tooltip id="refresh-tooltip" {...props}>
      {Data}
    </Tooltip>
  );

  const [Role, setRole] = useState([]);
  const [SelectedRole, setSelectedRole] = useState(null);
  const [ChangeHappend, setChangeHappend] = useState(null);
  const [dataSource, setDataSource] = useState([]);

  const fetchRole = async () => {
    const res = await GetRequest(ApiUrl.role);
    if (res) {
      setRole(res);
    }
    console.log(res);
  };
  const fetchData = async (id) => {
    const res = await GetRequest(`${ApiUrl.permission}/${id}`);
    if (res) {
      console.log(res);
      setDataSource(res?.Permission ?? []);
    }
  };

  useEffect(() => {
    fetchRole();
    // fetchData();
  }, []);

  // Function to handle permission changes
  const HandlePermissionChange = (name, type, checked) => {
    setChangeHappend("change");
    setDataSource((prevData) => {
      // Clone the previous data
      const updatedData = [...prevData]; // Make a shallow copy of the array

      // Find the specific permission object by a unique identifier (e.g., name or id)
      const permissionIndex = updatedData.findIndex(
        (perm) => perm.PermissionName === name
      ); // Use PermissionName or another unique property

      console.log(permissionIndex);

      if (permissionIndex !== -1) {
        // UPDATE the specific permission type
        updatedData[permissionIndex][type] = checked;

        // If "all" is toggled, set all permissions accordingly
        if (type === "all") {
          updatedData[permissionIndex] = {
            ...updatedData[permissionIndex],
            WRITE: checked,
            READ: checked,
            UPDATE: checked,
            DELETE: checked,
          };
        } else {
          // If any individual permission is unchecked, uncheck "all"
          const { WRITE, READ, UPDATE, DELETE } = updatedData[permissionIndex];
          updatedData[permissionIndex].all = WRITE && READ && UPDATE && DELETE; // Update "all" based on individual permissions
        }
      }

      return updatedData;
    });
  };

  const onSubmit = async () => {
    setChangeHappend("load");
    console.log(dataSource);
    const res = await PatchRequest(`${ApiUrl.permission}/${SelectedRole}`, {
      permission: dataSource,
    });
    if (res) {
      setChangeHappend(null);
    }
  };

  const RemoveData = async (id) => {
    console.log(id);

    const res = await DeleteRequest(`${ApiUrl.permission}/${id}`);
    if (res) {
      DeletConfirmation();
      fetchData(SelectedRole);
    }
  };
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Permission</h4>
                <h6>Manage your permissions</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={(d) => renderRefreshTooltip(d, "Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => fetchData(SelectedRole)}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <a
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#add-permission"
              >
                <PlusCircle className="me-2" />
                Add New Permission
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    {/* <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link> */}
                    <div>
                      {/* <p>Permission For :</p> */}
                      <label className="form-label">
                        Set Permission For :{" "}
                      </label>
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setSelectedRole(e.target.value);
                          fetchData(e.target.value);
                        }}
                      >
                        <option value={null} disabled selected>
                          Select a role
                        </option>
                        {Role.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.RoleName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table  datanew">
                  <thead>
                    <tr>
                      {/* <th className="no-sort">
                        <label className="checkboxs">
                          <input type="checkbox" id="select-all" />
                          <span className="checkmarks" />
                        </label>
                      </th> */}
                      <th>Permissions</th>
                      <th>WRITE</th>
                      <th>READ</th>
                      <th>UPDATE</th>
                      <th>DELETE</th>

                      <th className="no-sort">Allow all</th>
                      <th className="no-sort">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataSource?.map((permissionObj, index) => (
                      <tr key={index}>
                        {console.log(permissionObj)}
                        <td>{permissionObj.PermissionName}</td>
                        {["WRITE", "READ", "UPDATE", "DELETE"].map(
                          (permission) => (
                            <td
                              key={`${
                                permissionObj?.PermissionName ?? "samle"
                              }${permission}`}
                            >
                              <label className="checkboxs">
                                <input
                                  type="checkbox"
                                  checked={permissionObj[permission]}
                                  onChange={(e) =>
                                    HandlePermissionChange(
                                      permissionObj.PermissionName,
                                      permission,
                                      e.target.checked
                                    )
                                  }
                                />
                                <span className="checkmarks" />
                              </label>
                            </td>
                          )
                        )}
                        <td key={`${permissionObj?.PermissionName ?? "samle"}`}>
                          <label className="checkboxs">
                            <input
                              type="checkbox"
                              checked={
                                permissionObj.WRITE &&
                                permissionObj.READ &&
                                permissionObj.UPDATE &&
                                permissionObj.DELETE
                              }
                              onChange={(e) =>
                                HandlePermissionChange(
                                  permissionObj.PermissionName,
                                  "all",
                                  e.target.checked
                                )
                              }
                            />
                            <span className="checkmarks" />
                          </label>
                        </td>
                        <td>
                          <Link
                            className="confirm-text p-2"
                            to="#"
                            onClick={() =>
                              showDeleteConfirmationAlert(
                                permissionObj?.PermissionName,
                                RemoveData
                              )
                            }
                          >
                            <i
                              data-feather="trash-2"
                              className="feather-trash-2"
                            ></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!dataSource ||
                  (dataSource?.length == 0 && (
                    <div className="text-center mt-4 text-secondary">
                      Select a Role to proceed
                    </div>
                  ))}
                {ChangeHappend && (
                  <button className="btn btn-primary mt-4 " onClick={onSubmit}>
                    {" "}
                    {ChangeHappend === "load" ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <AddPermission onFinish={() => fetchData(SelectedRole)} />
    </div>
  );
};

export default Permissions;

// Permission: [
//   {
//     PermissionName: "Inventory",
//     WRITE: true,
//     READ: true,
//     UPDATE: true,
//     DELETE: true,
//   },
// ],
